<template>
  <a-select
    v-model:value="id"
    show-search
    :placeholder="$t('Select property')"
    style="width: 100%"
    dropdown-class-name="property-max-content-width"
    :default-active-first-option="false"
    :filter-option="false"
    :not-found-content="null"
    :size="size"
    :allow-clear="isAllowClear"
    :mode="mode"
    @search="delaySearch"
    @change="handleChange"
  >
    <a-select-option v-for="d in data" :key="d[returnValue]" :title="d[displayedText]">
      {{ d[displayedText] }}
    </a-select-option>
  </a-select>
</template>

<i18n>
{
  "en": {
    "Select property": "Select property"
  },
  "ja": {
    "Select property": "物件選択"
  }
}
</i18n>

<script>
import debounce from 'lodash/debounce';

export default {
  name: 'InputProperty',
  props: {
    value: {
      type: String,
      default() {
        return undefined;
      },
    },
    size: {
      type: String,
      default() {
        return 'large';
      },
    },
    partnerId: {
      type: Number,
      default: null,
    },
    neppanOnly: {
      type: Boolean,
    },
    returnValue: {
      type: String,
      default: 'newPropertyCode',
    },
    displayedText: {
      type: String,
      default: 'name',
    },
    useRoomType: {
      type: Boolean,
    },
    isAllowClear: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
    },
  },
  emits: ['change', 'update:value'],
  data() {
    return {
      id: undefined,
      data: [],
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        this.id = nv;
      },
    },
    partnerId: {
      handler() {
        this.handleSearch().then(() => {
          const exist = this.data.find((property) => property[this.returnValue] === this.id);

          if (!exist) {
            this.id = undefined;
          }
        });
      },
    },
  },
  created() {
    this.handleSearch();
  },
  methods: {
    handleSearch(value = '') {
      const params = { infinite: true };

      if (value.length > 0) {
        params.name = value;
      }

      if (this.partnerId) {
        params.partnerId = this.partnerId;
      }

      if (this.useRoomType === true) {
        params.useRoomType = true;
      }

      return this.$store.dispatch('properties/names', params).then((res) => {
        if (this.neppanOnly) {
          this.data = res.list.filter((p) => p.neppanPropertyCode);
        } else {
          this.data = res.list;
        }

        if (this.id) {
          this.handleChange(this.id);
        }
        return Promise.resolve();
      });
    },
    delaySearch: debounce(function delaySearch(value) {
      this.handleSearch(value);
    }, 600),
    handleChange(value) {
      this.id = value;
      const selected = this.data.find((p) => p.newPropertyCode === value);

      this.$emit('change', selected);
      this.$emit('update:value', this.id);
    },
  },
};
</script>

<style>
.property-max-content-width {
  min-width: 300px !important;
}
@media screen and (max-width: 320px) {
  .property-max-content-width {
    min-width: auto !important;
  }
}
</style>
