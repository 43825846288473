<template>
  <a-select
    v-model:value="id"
    show-search
    :placeholder="$t('Select housekeeper')"
    style="width: 100%"
    :default-active-first-option="false"
    :filter-option="false"
    :size="size"
    @search="delaySearch"
    @change="handleChange"
  >
    <a-select-option v-for="d in data" :key="d.uid">
      {{ getUserFullname(d) }}
      <span v-if="d.assignments && d.assignments.length">
        ({{ d.assignments.length }} {{ $t('room assignment') }})
      </span>
    </a-select-option>
  </a-select>
</template>

<i18n>
{
"en": {
"Select housekeeper": "Select housekeeper",
"room assignment": "room assignment"
},
"ja": {
"Select housekeeper": "清掃担当者選択",
"room assignment": "新規清掃の登録"
}
}
</i18n>

<script>
import debounce from 'lodash/debounce';
import { getFullname } from '@/utils/users';

export default {
  name: 'InputHousekeeper',
  props: {
    value: {
      type: String,
      default() {
        return undefined;
      },
    },
    size: {
      type: String,
      default() {
        return 'large';
      },
    },
    partnerId: {
      type: Number,
      default: null,
    },
    availableOnly: {
      type: Boolean,
    },
    cleaningDate: {
      type: String,
      default() {
        return null;
      },
    },
  },
  emits: ['change', 'update:value'],
  data() {
    return {
      data: [],
      id: undefined,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        if (this.id !== nv && nv) {
          this.$store
            .dispatch('user/users', {
              role: 'HKP, HC',
              partnerId: this.partnerId,
              infinite: true,
            })
            .then((res) => {
              this.data = res.list;
            });
        }
        this.id = nv;
      },
    },
    partnerId: {
      handler() {
        this.data = [];
        this.handleSearch().then(() => {
          const exist = this.data.find((hk) => hk.uid === this.id);

          if (!exist) {
            this.id = undefined;
          }
        });
      },
    },
  },
  created() {
    this.handleSearch();
  },
  methods: {
    handleSearch(value) {
      const query = {};
      if (this.availableOnly) {
        query.availableOnly = true;
      }
      if (this.cleaningDate) {
        query.cleaningDate = this.cleaningDate;
      }
      return this.$store
        .dispatch('user/users', {
          role: 'HKP,HC',
          name: value,
          partnerId: this.partnerId,
          infinite: true,
          ...query,
        })
        .then((res) => {
          this.data = res.list;

          return Promise.resolve();
        });
    },
    delaySearch: debounce(function delaySearch(value) {
      this.handleSearch(value);
    }, 600),
    handleChange(value) {
      this.id = value;
      this.$emit('change', this.id);
      this.$emit('update:value', this.id);
    },
    getUserFullname(record) {
      return getFullname({
        fname: record.firstName,
        lname: record.lastName,
      });
    },
    getAssignments({ assignments }) {
      return assignments.filter((assignment) => assignment.status === 'assigned').length;
    },
  },
};
</script>
