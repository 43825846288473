<template>
  <a-form ref="form" :model="form" :rules="rules" class="columns is-mini-gap is-multiline">
    <div class="column is-12">
      <h2 class="has-text-primary has-text-weight-bold is-size-4">
        {{ title }}
      </h2>
    </div>
    <a-form-item class="column is-12" :label="$t('Date Off')" name="dates">
      <el-date-picker
        v-model="form.dates"
        value-format="YYYY-MM-DD"
        class="dayoff-picker"
        :type="type"
        :placeholder="$t('Date Off')"
      />
    </a-form-item>

    <div class="column has-text-right is-12 m-t-4x">
      <a-button
        :disabled="isSubmitting"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="isSubmitting"
        :size="size"
        class="m-r-0"
        style="min-width: 145px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n>
{
  "en":{
    "Date Off": "Date Off",
    "Edit Day Off": "Edit Day Off",
    "Add Day Off": "Add Day Off",
    "Day off date range": "Day off date range",
    "Date range": "Date range",
    "Cancel": "Cancel",
    "Save": "Save"
  },
  "ja":{
    "Date Off": "休日",
    "Edit Day Off": "休日を編集",
    "Add Day Off": "休日を追加",
    "Day off date range": "休日範囲",
    "Date range": "休日範囲",
    "Cancel": "取り消し",
    "Save": "保存"
  }
}
</i18n>

<script>
export default {
  name: 'DayOffForm',
  components: {},
  props: {
    value: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  emits: ['submit', 'close', 'cancel'],
  data() {
    return {
      dateFormat: 'YYYY-MM-DD',
      form: {
        dates: undefined,
      },
      rules: {
        // todo
      },
      size: 'large',
      isSubmitting: false,
    };
  },
  computed: {
    type() {
      return this.value ? 'date' : 'dates';
    },
    title() {
      return this.value ? this.$t('Edit Day Off') : this.$t('Add Day Off');
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        if (nv) {
          this.form.dates = nv.dateOff;
        }
      },
    },
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$refs.form.validate();
        this.isSubmitting = true;
        const { dates } = this.form;

        if (this.value && this.value.id) {
          await this.$store.dispatch('working-date/updateDaysOff', {
            id: this.value.id,
            dateOff: dates,
          });
        } else {
          await this.$store.dispatch('working-date/addDaysOff', { dates });
        }
        this.$emit('close');
      } catch (e) {
        console.log(e);
      } finally {
        this.isSubmitting = false;
      }
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .dayoff-picker {
  width: 100%;
}
</style>
