<template>
  <a-form ref="form" :model="form" :rules="rules" class="columns is-mini-gap is-multiline">
    <div class="column is-12">
      <h2 class="has-text-primary has-text-weight-bold is-size-4">
        {{ $t('title') }}
      </h2>
    </div>
    <div class="column detail is-12">
      <table>
        <tr>
          <th>{{ $t('Property') }}</th>
          <td>{{ value.room && value.room.property.name }}</td>
        </tr>
        <tr>
          <th>{{ $t('Room') }}</th>
          <td>{{ value.room.name }}</td>
        </tr>
        <tr>
          <th>{{ $t('Checkout') }}</th>
          <td>
            {{ $filters.date(value.checkoutDate, { format: $variables.dateFormat }) }},
            {{ value.checkoutTime }}
          </td>
        </tr>
      </table>
    </div>
    <a-form-item class="column is-12" :label="$t('Assign to')" name="housekeeperId">
      <InputHousekeeper
        v-model:value="form.housekeeperId"
        :available-only="true"
        :cleaning-date="value.checkoutDate"
      />
    </a-form-item>

    <div class="column has-text-right is-12 m-t-4x">
      <a-button
        :disabled="isSubmitting"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="isSubmitting"
        :size="size"
        class="m-r-3x"
        style="min-width: 155px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Approve') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n>
{
"en": {
"title": "Approve this assignment?",
"Property": "Property",
"Room": "Room",
"Checkout": "Checkout",
"Assign to": "Assign to",
"Cancel": "Cancel",
"Approve": "Approve"
},
"ja": {
"title": "この割り当てを承認しますか？",
"Property": "物件",
"Room": "部屋",
"Checkout": "チェックアウト日",
"Assign to": "割り当てる",
"Cancel": "取り消し",
"Approve": "承認"
}
}
</i18n>

<script>
import InputHousekeeper from '@/components/InputHousekeeper';

export default {
  name: 'ApproveForm',
  components: { InputHousekeeper },
  props: {
    value: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  emits: ['close', 'cancel'],
  data() {
    return {
      form: {
        housekeeperId: undefined,
      },
      rules: {
        housekeeperId: [
          {
            required: true,
            message: this.$t('Housekeeper is required.'),
            trigger: ['change', 'blur'],
          },
        ],
      },
      size: 'large',
      isSubmitting: false,
    };
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$refs.form.validate();
        this.isSubmitting = true;
        await this.$store.dispatch('assignment-request/approve', {
          id: this.value.id,
          ...this.form,
        });
        this.$emit('close');
      } catch (e) {
        console.log(e);
      }
      this.isSubmitting = false;
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
.detail {
  table {
    th {
      font-weight: normal;
      color: #888888;
      padding-right: 1rem;
    }
  }
}
</style>
