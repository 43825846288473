<template>
  <div>
    <a-table
      row-key="id"
      :columns="columns"
      :data-source="value"
      :loading="isFetching"
      :pagination="pagination"
      @change="handleTableChange"
    >
      <template #checkoutDate="{ record }">
        {{ $filters.date(record.checkoutDate, { format: $variables.dateFormat }) }}
      </template>
      <template #property="{ record }">
        {{ record.room && record.room.property.abbreviation }}
      </template>
      <template #room="{ record }">
        {{ record.room && record.room.name }}
      </template>
      <template #action="{ record }">
        <a-button
          v-if="record.status !== 'assigned'"
          :size="size"
          class="approve-button"
          type="primary"
          @click="approve(record)"
        >
          {{ $t('Approve') }}
        </a-button>
        <a-button
          v-if="record.status !== 'rejected'"
          :size="size"
          class="button-secondary-outline reject-button"
          @click="reject(record)"
        >
          {{ $t('Reject') }}
        </a-button>
      </template>
    </a-table>
  </div>
</template>

<i18n>
{
"en": {
"Approve": "Approve",
"Reject": "Reject",
"Checkout date": "Checkout date",
"Checkout time": "Checkout time",
"Checkout Status": "Checkout Status",
"Property": "Property",
"Room Name": "Room Name"
},
"ja": {
"Approve": "承認",
"Reject": "却下",
"Checkout date": "清掃日",
"Checkout time": "時間",
"Checkout Status": "チェックアウト",
"Property": "物件",
"Room Name": "部屋"
}
}
</i18n>

<script>
export default {
  name: 'List',
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    isFetching: {
      type: Boolean,
    },
    size: {
      type: String,
      default() {
        return 'large';
      },
    },
    pagination: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  emits: ['approve', 'reject', 'table-change'],
  computed: {
    columns() {
      return [
        {
          title: this.$t('Property'),
          dataIndex: 'property',
          slots: { customRender: 'property' },
        },
        {
          title: this.$t('Room Name'),
          dataIndex: 'room',
          slots: { customRender: 'room' },
        },
        {
          title: this.$t('Checkout date'),
          dataIndex: 'checkoutDate',
          slots: { customRender: 'checkoutDate' },
        },
        {
          title: this.$t('Checkout time'),
          dataIndex: 'checkoutTime',
          customRender: ({ record }) => {
            const property = record.room && record.room.property;
            return (property.policies && property.policies.checkOutTime) || '–';
          },
        },
        {
          title: ' ',
          dataIndex: 'action',
          key: 'action',
          slots: { customRender: 'action' },
          width: 300,
        },
      ];
    },
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      this.$emit('table-change', pagination, filters, sorter);
    },
    approve(record) {
      this.$emit('approve', record);
    },
    reject(record) {
      this.$emit('reject', record);
    },
  },
};
</script>

<style lang="scss" scoped>
.approve-button {
  margin-right: 15px;
  min-width: 120px;
  @include mobile {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.reject-button {
  @include mobile {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  min-width: 120px;
}
</style>
