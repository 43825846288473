<template>
  <div class="columns form is-mini-gap is-multiline">
    <div class="column is-narrow">
      <a-input-group compact>
        <a-range-picker
          v-model:value="range"
          class="is-mobile-field"
          :size="size"
          style="max-width: 200px"
          :format="dateFormat"
          @change="debounceQuery"
        >
          <a-input
            :value="rangeString"
            :placeholder="$t('Checkout range')"
            :size="size"
            readonly
          >
            <template #prefix>
              <CalendarFilled />
            </template>
          </a-input>
        </a-range-picker>
      </a-input-group>
    </div>
    <div class="column is-3">
      <InputProperty
        v-model:value="propertyId"
        :size="size"
        displayed-text="abbreviation"
        style="width: 100%"
        @change="debounceQuery"
      />
    </div>
    <div class="column is-2">
      <a-button
        v-show="range.length || propertyId"
        :size="size"
        style="min-width: 110px"
        type="default"
        @click="handleClear"
      >
        Clear
      </a-button>
    </div>
  </div>
</template>

<i18n>
{
"en": {
"Checkout range": "Checkout range"
},
"ja": {
"Checkout range": "チェックアウト日"
}
}
</i18n>

<script>
import debounce from 'lodash/debounce';
import { CalendarFilled } from '@ant-design/icons-vue';
import InputProperty from '@/components/InputProperty';

export default {
  name: 'SearchForm',
  components: { CalendarFilled, InputProperty },
  props: {
    size: {
      type: String,
      default: 'large',
    },
    dateFormat: {
      type: String,
      default: 'MM/DD/YYYY',
    },
  },
  emits: ['filter'],
  data() {
    return {
      range: [],
      propertyId: undefined,
    };
  },
  computed: {
    rangeString() {
      return this.range.length
        ? `${this.$filters.date(this.range[0], { format: this.dateFormat })} ~ ${this.$filters.date(
          this.range[1],
          { format: this.dateFormat },
        )}`
        : '';
    },
    formattedRange() {
      const dateFormat = 'YYYY-MM-DD';
      return this.range.length
        ? `${this.$filters.date(this.range[0], { format: dateFormat })},${this.$filters.date(
          this.range[1],
          { format: dateFormat },
        )}`
        : undefined;
    },
  },
  mounted() {
    this.range = this.$route.query.dateFrom || this.$route.query.dateTo
      ? [this.$route.query.dateFrom, this.$route.query.dateTo] : [];
  },
  methods: {
    // eslint-disable-next-line func-names
    debounceQuery: debounce(async function () {
      const querySearch = {};
      const sort = {};
      if (this.range.length) {
        const [from, to] = this.formattedRange.split(',');
        querySearch.dateFrom = from;
        querySearch.dateTo = to;
        sort.sortField = 'cleaningDate';
        sort.sortOrder = 'ASC';
      }

      if (this.propertyId) {
        querySearch.propertyId = this.propertyId;
      }

      await this.$router.push({
        query: {
          page: 1,
          ...querySearch,
          ...sort,
        },
      });
      this.$emit('filter');
    }, 600),
    handleClear() {
      this.range = [];
      this.propertyId = undefined;
      this.debounceQuery();
    },
  },
};
</script>

<style>

</style>
