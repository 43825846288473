<template>
  <div>
    <a-table
      :columns="columns"
      :data-source="value"
      :loading="isFetching"
      :custom-row="rowClicked"
      row-key="id"
    >
      <template #range="{ record }">
        {{ $filters.date(record.dateOff, { format: $variables.dateFormat }) }}
      </template>
      <template #status="{ record }">
        <span class="has-text-capitalize"> {{ getStatus(record) }}</span>
      </template>
      <template #action="{ record }">
        <div class="is-flex is-justify-content-flex-end">
          <EditFilled
            v-role="['HKP']"
            class="has-text-primary mr-2 mt-1"
            @click.prevent="edit(record)"
          />
          <DelDayOff v-role="['HKP']" :record="record" @delete="$emit('delete')" />
        </div>
      </template>
    </a-table>
  </div>
</template>

<i18n>
{
  "en": {
    "Day off": "Day off",
    "Status": "Status",
    "Taken": "Taken",
    "Upcoming": "Upcoming"
  },
  "ja": {
    "Day off": "休日",
    "Status": "ステータス",
    "Taken": "予約済み",
    "Upcoming": "今後"
  }
}
</i18n>

<script>
import dayjs from 'dayjs';
import { EditFilled } from '@ant-design/icons-vue';
import DelDayOff from '@/views/working-date/components/DelDayOff';

export default {
  name: 'List',
  components: {
    EditFilled,
    DelDayOff,
  },
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    isFetching: {
      type: Boolean,
    },
  },
  emits: ['delete', 'edit'],
  computed: {
    columns() {
      return this.$mq === 'mobile'
        ? [
          {
            title: this.$t('Day off'),
            dataIndex: 'dateOff',
            slots: { customRender: 'dateOff' },
          },
          {
            title: ' ',
            dataIndex: 'action',
            key: 'action',
            slots: { customRender: 'action' },
            width: 80,
          },
        ]
        : [
          {
            title: this.$t('Day off'),
            dataIndex: 'range',
            slots: { customRender: 'range' },
          },
          {
            title: this.$t('Status'),
            dataIndex: 'status',
            slots: { customRender: 'status' },
          },
          {
            title: ' ',
            dataIndex: 'action',
            key: 'action',
            slots: { customRender: 'action' },
            width: 80,
          },
        ];
    },
  },
  methods: {
    getStatus(record) {
      return dayjs().isAfter(record.dateOff, 'd') ? this.$t('Taken') : this.$t('Upcoming');
    },
    edit(record) {
      this.$emit('edit', { id: record.id, dateOff: record.dateOff });
    },
  },
};
</script>

<style lang="scss" scoped>
.name-wrap {
  position: relative;
  padding-left: 25px;

  .circle-danger {
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -5px;
  }
}
</style>
