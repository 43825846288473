<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-mobile">
            <div class="column">
              <h2 class="is-3 title">
                {{ $t('Assignment Request') }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container">
        <div class="columns is-mobi">
          <div class="column">
            <SearchForm @filter="fetchData" />
          </div>
        </div>
        <List
          :value="list"
          :is-fetching="isFetching"
          :pagination="pagination"
          @approve="approve"
          @reject="reject"
          @table-change="handleTableChange"
        />
      </div>
    </div>
    <Modal ref="approveModal">
      <template #default="{ hide }">
        <ApproveForm
          v-if="selectRecord"
          :value="selectRecord"
          @cancel="hide"
          @close="
            () => {
              hide();
              fetchData();
            }
          "
        />
      </template>
    </Modal>
    <Modal ref="delModal">
      <template #default="{ hide }">
        <RejectForm
          v-if="selectRecord"
          :value="selectRecord"
          @cancel="hide"
          @close="
            () => {
              hide();
              fetchData();
            }
          "
        />
      </template>
    </Modal>
  </div>
</template>

<i18n>
{
  "en": {
    "Assignment Request": "Assignment Request"
  },
  "ja": {
    "Assignment Request": "割り振りをリクエスト"
  }
}
</i18n>

<script>
import pagination from '@/components/mixins/pagination';
import Modal from '@/components/Modal';
import List from '@/views/assignment-request/components/List';
import ApproveForm from '@/views/assignment-request/components/ApproveForm';
import RejectForm from '@/views/assignment-request/components/RejectForm';
import SearchForm from '@/views/assignment-request/components/SearchForm';

export default {
  name: 'AssignmentRequest',
  components: {
    SearchForm,
    RejectForm,
    ApproveForm,
    Modal,
    List,
  },
  mixins: [pagination],
  data() {
    return {
      size: 'large',
      list: undefined,
      isFetching: false,
      selectRecord: undefined,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async handleTableChange(pagination) {
      await this.preparePagination(pagination);
      await this.fetchData();
    },
    fetchData() {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');

      return this.$store.dispatch('assignment-request/list', this.$route.query)
        .then((res) => {
          const { list, pagination } = res;

          this.list = list.map((p, index) => {
            p._id = index + 1;

            return p;
          });

          if (pagination) {
            this.setPagination(pagination);
          }
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
    approve(record) {
      this.selectRecord = record;
      this.$refs.approveModal.show();
    },
    reject(record) {
      this.selectRecord = record;
      this.$refs.delModal.show();
    },
  },
};
</script>
