<template>
  <Modal>
    <template #default="{ hide }">
      <div>
        <div v-if="step === 1" class="columns is-multiline">
          <div class="column is-12">
            <h3 class="has-text-primary has-text-weight-bold is-size-4">
              <i class="el-icon-warning has-text-danger" /> {{ title }}
            </h3>
          </div>
          <div class="column is-12">
            <p v-html="description" />
          </div>
          <div class="column has-text-right is-12">
            <a-button class="m-r-2x" style="min-width: 125px" :disabled="loading" @click="hide">
              {{ $t('Cancel') }}
            </a-button>
            <a-button type="danger" style="min-width: 125px" :loading="loading" @click="handleDel">
              {{ $t('delete') }}
            </a-button>
          </div>
        </div>
        <div v-if="step === 2" class="columns is-multiline">
          <div class="column is-12">
            <h3 class="has-text-secondary has-text-weight-bold is-size-4">
              {{ successTitle }}
            </h3>
          </div>
          <div class="column is-12">
            <p v-html="successDescription" />
          </div>
          <div class="column has-text-right is-12">
            <a-button
              class="button-secondary-outline"
              style="min-width: 125px"
              @click="
                () => {
                  handleDeleteSuccess();
                  hide();
                }
              "
            >
              {{ $t('Ok') }}
            </a-button>
          </div>
        </div>
      </div>
    </template>
    <template #handler="{ show }">
      <slot name="handler" :show="show">
        <DeleteFilled
          v-if="!hideHandler"
          v-permission="handlerVPermission"
          class="has-text-danger ml-3"
          @click.stop.prevent="show"
        />
      </slot>
    </template>
  </Modal>
</template>

<i18n>
{
  "en": {
    "Cancel": "Cancel",
    "delete": "Delete",
    "Ok": "Ok"
  },
  "ja": {
    "Cancel": "取り消し",
    "delete": "削除",
    "Ok": "Ok"
  }
}
</i18n>

<script>
import { DeleteFilled } from '@ant-design/icons-vue';
import Modal from '@/components/Modal';

export default {
  name: 'PopupDelete',
  components: {
    Modal,
    DeleteFilled,
  },
  props: {
    actionHandler: {
      required: true,
      type: String,
      default: '',
    },
    payloadValue: {
      type: String || Object,
      default: 'id',
    },
    handlerVPermission: {
      type: Array,
      default: () => [],
    },
    hideHandler: {
      type: Boolean,
    },
    title: {
      type: String,
      default: 'Delete this item?',
    },
    description: {
      type: String,
      default: 'Are you sure want to delete this item?',
    },
    successTitle: {
      type: String,
      default: 'Item is deleted',
    },
    successDescription: {
      type: String,
      default: 'The item has been removed.',
    },
  },
  emits: ['cancel', 'close'],
  data() {
    return {
      step: 1,
      loading: false,
    };
  },
  methods: {
    handleDeleteSuccess() {
      this.$emit('close');
    },
    handleDel() {
      this.loading = true;
      this.$store
        .dispatch(this.actionHandler, this.payloadValue)
        .then(() => {
          this.loading = false;
          this.step = 2;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
