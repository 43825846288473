<template>
  <Delete
    action-handler="working-date/deleteDaysOff"
    :payload-value="{ id: record.id }"
    :title="$t('title')"
    :description="$t('desc', { name: record.name })"
    :success-title="$t('deleted-title')"
    :success-description="$t('deleted-desc', { name: record.name })"
    @close="$emit('delete')"
  />
</template>

<i18n>
{
"en":{
"title": "Delete Day Off?",
"desc": " Are you use you want to delete it ?",
"deleted-title": "Day Off is deleted",
"deleted-desc": "Day Off has been removed."
},
"ja":{
"title": "休日を削除?",
"desc": "本当に休日を削除しますか？",
"deleted-title": "休日削除完了",
"deleted-desc": "休日は削除されています"
}
}
</i18n>

<script>
import Delete from '@/components/PopupDelete';

export default {
  name: 'DeleteWorkingDate',
  components: {
    Delete,
  },
  props: {
    record: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['delete'],
};
</script>
