<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-mobile">
            <div class="column">
              <h2 class="is-3 title">
                {{ $t('Working Date') }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container">
        <Weekdays />
        <hr>
        <div class="columns is-mobile">
          <div class="column">
            <h2 class="has-text-primary has-text-weight-bold is-size-4">
              {{ $t('Day Off') }}
            </h2>
          </div>
          <div class="column has-text-right-mobile is-narrow">
            <a-button
              v-permission="['add-working-date']"
              v-role="['HKP', 'HC']"
              :size="size"
              style="min-width: 180px"
              type="primary"
              @click="editDayOff(undefined)"
            >
              {{ $t('Add Day Off') }}
            </a-button>
          </div>
        </div>
        <List :value="list" :is-fetching="isFetching" @edit="editDayOff" @delete="fetchData" />
      </div>
    </div>
    <Modal ref="dayoffModal">
      <template #default="{ hide }">
        <DayOffForm
          :value="selectRecord"
          @cancel="hide"
          @close="
            () => {
              hide();
              fetchData();
            }
          "
        />
      </template>
    </Modal>
  </div>
</template>

<i18n>
{
"en": {
"Working Date": "Working Date",
"Day Off": "Day Off",
"Add Day Off": "Add Day Off"
},
"ja": {
"Working Date": "勤務日",
"Day Off": "休日",
"Add Day Off": "休日を追加"
}
}
</i18n>

<script>
import Modal from '@/components/Modal';
import Weekdays from '@/views/working-date/components/Weekdays';
import List from '@/views/working-date/components/List';
import DayOffForm from '@/views/working-date/components/DayOffForm';

export default {
  name: 'WorkingDate',
  components: {
    DayOffForm,
    Weekdays,
    Modal,
    List,
  },
  data() {
    return {
      size: 'large',
      list: undefined,
      isFetching: false,
      selectRecord: undefined,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      this.$store
        .dispatch('working-date/getDaysOff')
        .then((resp) => {
          this.list = resp.list.map((p, index) => {
            p._id = index + 1;
            return p;
          });
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
    onAdd() {
      this.$router.push({ name: 'alerts-add' });
    },
    editDayOff(record) {
      this.selectRecord = record;
      this.$refs.dayoffModal.show();
    },
    delDayOff(record) {
      this.selectRecord = record;
      this.$refs.delModal.show();
    },
  },
};
</script>
