<template>
  <div>
    <div class="columns is-mobi is-multiline">
      <div class="column is-12">
        <h2 class="has-text-primary has-text-weight-bold is-size-4">
          {{ $t('Working weekdays') }}
        </h2>
      </div>
      <div class="checkbox-group-btn-style column is-12">
        <p class="m-b-2x">{{ $t('Select your working days') }}:</p>
        <a-checkbox-group v-model:value="value">
          <a-checkbox v-for="v in options" :key="v.value" :value="v.value">
            {{ $t(v.text) }}
          </a-checkbox>
        </a-checkbox-group>
      </div>
      <div v-role="['HC']" class="column is-12">
        <a-button
          :loading="isSubmitting"
          :size="size"
          style="min-width: 155px; margin-top: 1rem"
          type="primary"
          @click="onSubmit"
        >
          {{ $t('Save') }}
        </a-button>
      </div>
    </div>
  </div>
</template>

<i18n>
{
"en": {
"Working weekdays": "Working weekdays",
"Select your working days": "Select your working days",
"Save": "Save",
"MON": "MON",
"TUE": "TUE",
"WED": "WED",
"THU": "THU",
"FRI": "FRI",
"SAT": "SAT",
"SUN": "SUN"
},
"ja": {
"Working weekdays": "出勤日",
"Select your working days": "出勤日を選択",
"Save": "保存",
"MON": "月曜日",
"TUE": "火曜日",
"WED": "水曜日",
"THU": "木曜日",
"FRI": "金曜日",
"SAT": "土曜日",
"SUN": "日曜日"
}
}
</i18n>

<script>
export default {
  name: 'Weekdays',
  props: {
    size: {
      type: String,
      default: 'large',
    },
  },
  data() {
    return {
      value: [],
      isSubmitting: false,
      options: [
        { value: 1, text: 'MON' },
        { value: 2, text: 'TUE' },
        { value: 3, text: 'WED' },
        { value: 4, text: 'THU' },
        { value: 5, text: 'FRI' },
        { value: 6, text: 'SAT' },
        { value: 0, text: 'SUN' },
      ],
    };
  },
  created() {
    this.asyncData();
  },
  methods: {
    async asyncData() {
      try {
        this.$store.commit('SHOW_FULLSCREEN_LOADER');
        const { list } = await this.$store.dispatch('working-date/list');
        this.value = list;
      } catch (e) {
        console.log(e);
      }
      this.$store.commit('HIDE_FULLSCREEN_LOADER');
    },
    async onSubmit() {
      try {
        this.isSubmitting = true;
        await this.$store.dispatch('working-date/update', { days: this.value });
        this.asyncData();
        this.isSubmitting = false;
      } catch (e) {
        console.log(e);
      }
      this.isSubmitting = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox-group-btn-style :deep {
  .ant-checkbox {
    display: none;
  }

  .ant-checkbox-wrapper {
    padding: 6px 6px;
    border: 2px solid #888888;
    border-radius: 5px;
    background-color: #ffffff;
    color: #888888;

    span {
      padding: 0 !important;
      user-select: none;
    }

    &.ant-checkbox-wrapper-checked {
      border-color: #00596c;
      background-color: #00596c;
      color: #ffffff;
    }
  }
}
</style>
